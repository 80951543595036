import React from "react";
import Layout from "../component/layout";
// import HomeImage from "../images/custom-development-home-image.png";  per tu  fshir
import WebsLogoSwiper from "../component/websLogoSwiper";
import { graphql } from "gatsby";
import CustomDevelopmentDriveComponent from "../component/Services/CustomDevelopment/customDevelopmentDriveComponent";
import CardsComponent from "../component/CardsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import Img from "../images/core-image-2.png";
import CustomDevelopmentCoreIndustries from "../component/Services/CustomDevelopment/customDevelopmentCoreIndustries";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import FooterComponent from "../component/footerComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import AccordionComponent from "../component/accordionComponent";
import BorderCards from "../component/BorderCards";

import Icon1 from "../images/card-icon-1.png";
import Icon2 from "../images/card-icon-2.png";
import Icon3 from "../images/card-icon-3.png";
import Icon4 from "../images/card-icon-4.png";
import Icon5 from "../images/card-icon-5.png";
import Icon6 from "../images/card-icon-6.png";
import Icon7 from "../images/card-icon-7.png";
import Icon8 from "../images/card-icon-8.png";
import Icon9 from "../images/card-icon-9.png";

const ServiceCustomDevelopment = (props) => {
  const {
    data: {
      page1: {
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
        },
      },
      page2: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: homeImg },
            },
          },
        },
        blogAlbaniaArticle: {
          blogAlbaniaArticleTitle,
          blogAlbaniaArticleAuthor,
          blogAlbaniaArticleText,
          blogAlbaniaArticleData,
          blogAlbaniaArticleDescription,
        },
        customDevelopmentCards: {
          customDevelopmentTitle,
          customDevelopmentDescription,
          customDevelopmentCardTitle1,
          customDevelopmentCardDescription1,
          customDevelopmentCardTitle2,
          customDevelopmentCardDescription2,
          customDevelopmentCardTitle3,
          customDevelopmentCardDescription3,
          customDevelopmentCardTitle4,
          customDevelopmentCardDescription4,
          customDevelopmentCardTitle5,
          customDevelopmentCardDescription5,
          customDevelopmentCardTitle6,
          customDevelopmentCardDescription6,
          customDevelopmentCardTitle7,
          customDevelopmentCardDescription7,
          customDevelopmentCardTitle8,
          customDevelopmentCardDescription8,
          customDevelopmentCardTitle9,
          customDevelopmentCardDescription9,
        },

        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderBookButton,
          homeHeaderImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: Img },
            },
          },
        },
        projectSolutionsSection: {
          projectSolutionsTitle,
          projectSolutionSubtitle,
          projectSolutionsUserExperienceTitle,
          projectSolutionsUserExperienceDescription,
          projectSolutionsWebApplicationTitle,
          projectSolutionsWebApplicationDescription,
          projectSolutionsNewFeaturesTitle,
          projectSolutionsNewFeaturesDescription,
          projectSolutionsNextLevelTitle,
          projectSolutionsNextLevelDescription,
          projectWebSectionOptimisationTitle,
          projectWebSectionOptimisationDescription,
        },
      },
    },
  } = props;

  const cards = [
    {
      icon: Icon1,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon2,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon3,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon4,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon5,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon6,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon7,
      title: customDevelopmentCardTitle7,

      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon8,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
    {
      icon: Icon9,
      title: customDevelopmentCardTitle9,
      description: customDevelopmentCardDescription9,
    },
  ];
  const questions1 = [
    {
      title: projectSolutionsUserExperienceTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: projectSolutionsUserExperienceDescription.replace(
              /<br\s?\/?>/g,
              ""
            ),
          }}
        />
      ),
      eventKey: 1,
    },
    {
      title: projectSolutionsWebApplicationTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: projectSolutionsWebApplicationDescription.replace(
              /<br\s?\/?>/g,
              ""
            ),
          }}
        />
      ),
      eventKey: 2,
    },
    {
      title: projectSolutionsNewFeaturesTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: projectSolutionsNewFeaturesDescription.replace(
              /<br\s?\/?>/g,
              ""
            ),
          }}
        />
      ),
      eventKey: 3,
    },
  ];

  const questions2 = [
    {
      title: projectSolutionsNextLevelTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: projectSolutionsNextLevelDescription.replace(
              /<br\s?\/?>/g,
              ""
            ),
          }}
        />
      ),
      eventKey: 4,
    },
    {
      title: projectWebSectionOptimisationTitle,

      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{
            __html: projectWebSectionOptimisationDescription.replace(
              /<br\s?\/?>/g,
              ""
            ),
          }}
        />
      ),
      eventKey: 5,
    },
  ];

  const seo = {
    title: "Custom Software Development | IT Solutions | Motomtech",
    metaDesc:
      "Transform your business with a custom software by leveraging Motomtech’s custom software development solutions tailored to the needs of your team.",
  };
  return (
    <Layout title={seo.title} seo={seo}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={homeImg}
        homeButton={"Get a Quote"}
      />
      <WebsLogoSwiper />
      <CustomDevelopmentDriveComponent
        title={blogAlbaniaArticleTitle}
        text1={blogAlbaniaArticleAuthor}
        text2={blogAlbaniaArticleText}
        text3={blogAlbaniaArticleData}
        text4={blogAlbaniaArticleDescription}
      />
      <BorderCards
        borderCardsTitle={careerPositionTitle}
        borderCardsDescription={careerPositionDescription}
        borderCardsText1={careerPositionFornend}
        borderCardsText2={careerPositionBackend}
        borderCardsText3={careerPositionDevops}
        borderCardsText4={careerPositionDesigner}
        borderCardsText5={careerPositionQa}
        borderCardsText6={careerPositionProjectManagement}
        borderCardsText7={careerPositionProjectManager}
        borderCardsText8={careerPositionSupport}
        borderCardsText9={careerPositionCostumerSupport}
        borderCardsText10={careerPositionContentManager}
        borderCardsText11={careerPositionSocialMediaMarketing}
        borderCardsText12={careerPositionMarketingManager}
      />
      <CardsComponent
        cards={cards}
        title={customDevelopmentTitle}
        description={customDevelopmentDescription}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <TechStackComponent />
      </div>
      <CustomDevelopmentCoreIndustries
        image={Img}
        title={homeHeaderTitle}
        text1={homeHeaderDescription}
        text2={homeHeaderBookButton}
      />
      <div style={{ position: "relative" }}>
        <CustomDevelopmentVideoComponent />
      </div>
      <AccordionComponent
        title={projectSolutionsTitle}
        subtitle={projectSolutionSubtitle}
        arr1={questions1}
        arr2={questions2}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={
            "Turn Your Business Idea Into an MVP You Need to Break the Glass Ceiling"
          }
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};
export default ServiceCustomDevelopment;
export const query = graphql`
  {
    page1: wpPage(slug: { eq: "saas-mvp" }) {
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
      }
    }
    page2: wpPage(slug: { eq: "service-custom-development" }) {
      seo {
        title
        metaDesc
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderBookButton
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      customDevelopmentCards {
        customDevelopmentTitle
        customDevelopmentDescription
        customDevelopmentCardTitle1
        customDevelopmentCardDescription1
        customDevelopmentCardTitle2
        customDevelopmentCardDescription2
        customDevelopmentCardTitle3
        customDevelopmentCardDescription3
        customDevelopmentCardTitle4
        customDevelopmentCardDescription4
        customDevelopmentCardTitle5
        customDevelopmentCardDescription5
        customDevelopmentCardTitle6
        customDevelopmentCardDescription6
        customDevelopmentCardTitle7
        customDevelopmentCardDescription7
        customDevelopmentCardTitle8
        customDevelopmentCardDescription8
        customDevelopmentCardTitle9
        customDevelopmentCardDescription9
      }
      projectSolutionsSection {
        projectSolutionsTitle
        projectSolutionsUserExperienceTitle
        projectSolutionsUserExperienceDescription
        projectSolutionsWebApplicationTitle
        projectSolutionsWebApplicationDescription
        projectSolutionsNewFeaturesTitle
        projectSolutionsNewFeaturesDescription
        projectSolutionsNextLevelTitle
        projectSolutionsNextLevelDescription
        projectWebSectionOptimisationTitle
        projectWebSectionOptimisationDescription
        projectSolutionSubtitle
      }
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogAlbaniaArticle {
        blogAlbaniaArticleTitle
        blogAlbaniaArticleAuthor
        blogAlbaniaArticleText
        blogAlbaniaArticleData
        blogAlbaniaArticleDescription
      }
    }
  }
`;
