import { Link } from 'gatsby';
import React from 'react';

const CustomDevelopmentDriveComponent = ({
  title,
  text1,
  text2,
  text3,
  text4,
}) => {
  return (
    <div className='custom-dev-drive'>
      <div className='custom-dev-drive__content'>
        <p
          className='custom-dev-drive__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='custom-dev-drive__desc'
          dangerouslySetInnerHTML={{ __html: text1 }}
        />
        <p
          className='custom-dev-drive__desc'
          dangerouslySetInnerHTML={{ __html: text2 }}
        />
        <p
          className='custom-dev-drive__desc'
          dangerouslySetInnerHTML={{ __html: text3 }}
        />
        <p
          className='custom-dev-drive__desc'
          dangerouslySetInnerHTML={{ __html: text4 }}
        />
        <Link className='custom-dev-drive__link' to='/contact'>
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default CustomDevelopmentDriveComponent;
